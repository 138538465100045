import { charMax, requiredInput, requiredSelect, priceFn } from "@/rules";
export const rules = {
    carId: [requiredSelect()],
    companyId: [requiredSelect()],
    accidentLocation: [requiredInput(), charMax(90)],
    accidentTime: [requiredSelect()],
    accidentNature: [requiredSelect(), charMax(40)],
    accidentAccident: [requiredInput()],
    damage: [requiredInput(), charMax(180)],
    directEconomicLoss: [requiredInput(), priceFn()],
};
