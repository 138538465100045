<template>
    <el-dialog
        center
        v-bind="$attrs"
        v-on="$listeners"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form
            class="g-el-form"
            :model="ajaxParam"
            :rules="rules"
            ref="refElForm"
            label-width="160px"
        >
            <el-form-item
                :label="`${'所属公司'}:`"
                prop="companyId"
                v-if="$admin"
            >
                <GlobalCompanySelect
                    v-model="ajaxParam.companyId"
                    placeholder="请选择所属公司"
                    @change="
                        () => {
                            ajaxParam.carId = '';
                        }
                    "
                ></GlobalCompanySelect>
            </el-form-item>
            <el-form-item :label="`${'车牌号'}:`" prop="carId">
                <GlobalCarSelect
                    v-model="ajaxParam.carId"
                    :companyId="ajaxParam.companyId"
                    @clear="ajaxParam.carId = ''"
                />
            </el-form-item>
            <el-form-item :label="`${'事故发生地点'}:`" prop="accidentLocation">
                <el-input
                    v-model="ajaxParam.accidentLocation"
                    placeholder="请输入事故发生地点"
                ></el-input>
            </el-form-item>

            <el-form-item :label="`${'事故发生日期'}:`" prop="accidentTime">
                <GlobalElDatePicker
                    v-model="ajaxParam.accidentTime"
                    placeholder="请选择事故发生日期"
                ></GlobalElDatePicker>
            </el-form-item>
            <el-form-item :label="`${'事故性质'}:`" prop="accidentNature">
                <el-input
                    v-model="ajaxParam.accidentNature"
                    placeholder="请选择事故性质"
                ></el-input>
            </el-form-item>
            <el-form-item :label="`${'事故原因'}:`" prop="accidentAccident">
                <el-input
                    v-model="ajaxParam.accidentAccident"
                    placeholder="请输入事故原因"
                ></el-input>
            </el-form-item>
            <el-form-item :label="`${'车辆损坏情况'}:`" prop="damage">
                <el-input
                    v-model="ajaxParam.damage"
                    placeholder="请输入车辆损坏情况"
                ></el-input>
            </el-form-item>
            <el-form-item
                :label="`${'直接经济损失（元）'}:`"
                prop="directEconomicLoss"
            >
                <el-input
                    v-model="ajaxParam.directEconomicLoss"
                    placeholder="请输入直接经济损失"
                ></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" size="small" @click="onConfirm"
                >确定</el-button
            >
            <el-button type="info" size="small" @click="onCancel"
                >取消</el-button
            >
        </div>
    </el-dialog>
</template>

<script>
import { rules } from "./rules";
export default {
    computed: {
        rules() {
            return rules;
        },
    },
    title: "操作数据",
    name: "OperatData",
    props: {
        op: {
            type: Object,
            default: () => {
                return {
                    id: "0",
                    // show: false,
                    // row: null,
                    code: "init",
                };
            },
        },
    },

    data() {
        return {
            ajaxParam: {
                companyId: this.$admin ? "" : this.$companyId, // 公司id
                carId: "", // 车牌号
                accidentLocation: "", // 事故发生地点(90)
                accidentTime: "", // 事故发生日期
                accidentNature: "", // 事故性质(40)
                accidentAccident: "", // 事故原因(180)
                damage: "", // 车辆损坏情况(180)
                directEconomicLoss: "", // 直接经济损失（元）
            },
        };
    },
    created() {
        if (this.op.id != "0") this.lookfor();
    },
    methods: {
        onConfirm() {
            this.$refs.refElForm.validate((valid) => {
                if (valid) {
                    this.submitData();
                }
            });
        },
        async lookfor() {
            // 查看详情
            let { code, data } = await this.$api.get(
                `/car/server/escort/web/CarAccidentRecord/${this.op.id}`
            );
            if (code == 200) {
                Object.keys(this.ajaxParam).forEach((key) => {
                    this.ajaxParam[key] = data[key];
                });
            }
        },
        async submitData() {
            // 提交(编辑)数据
            let { code } =
                this.op.id == "0"
                    ? await this.$api.post(
                          "/car/server/escort/web/CarAccidentRecord",
                          this.ajaxParam
                      )
                    : await this.$api.put(
                          `/car/server/escort/web/CarAccidentRecord/${this.op.id}`,
                          this.ajaxParam
                      );
            if (code == 200) {
                this.$message({
                    message: "操作成功",
                    type: "success",
                    center: true,
                });
                this.$emit("query");
                this.onCancel();
            }
        },
        onCancel() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
.g-el-form {
    padding: 0 50px;
    .el-select {
        width: 100%;
    }
    .el-date-editor {
        width: 100%;
    }
}
</style>
