<template>
    <div class="dashboard-container">
        <el-row :gutter="20">
            <el-card>
                <el-row type="flex">
                    <el-col :span="6" v-if="$admin">
                        <span class="text-primary text-pad-right">公司：</span>
                        <GlobalCompanySelect
                            v-model="ajaxParam.companyId"
                            size="mini"
                            suffix-icon="el-icon-search"
                            style="width: 250px"
                            @change="
                                () => {
                                    ajaxParam.carId = '';
                                    queryList();
                                }
                            "
                        />
                    </el-col>
                    <el-col :span="6">
                        <span class="text-primary text-pad-right">车辆：</span>
                        <GlobalCarSelect
                            v-model="ajaxParam.carId"
                            size="mini"
                            style="width: 250px"
                            @clear="ajaxParam.carId = ''"
                            :companyId="ajaxParam.companyId"
                            @change="queryList"
                        />
                    </el-col>
                    <el-col :span="6">
                        <span class="text-primary text-pad-right">日期：</span>
                        <GlobalElDatePicker
                            start-placeholder="请选择开始时间"
                            end-placeholder="请选择结束时间"
                            type="daterange"
                            size="mini"
                            v-model="daterange"
                            @change="
                                (val) => {
                                    val
                                        ? (ajaxParam.startTime = val[0])
                                        : (ajaxParam.startTime = '');
                                    val
                                        ? (ajaxParam.endTime = val[1])
                                        : (ajaxParam.endTime = '');
                                    queryList();
                                }
                            "
                        />
                    </el-col>
                </el-row>
            </el-card>

            <el-card style="margin-top: 20px">
                <el-row>
                    <vxe-toolbar>
                        <template v-slot:buttons>
                            <el-row type="flex" justify="between">
                                <el-col>
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="
                                            op.id = '0';
                                            op.code = 'add';
                                            op.show = true;
                                        "
                                        >添加</el-button
                                    >
                                    <el-button
                                        type="danger"
                                        size="mini"
                                        @click="del([])"
                                        >批量删除</el-button
                                    >
                                </el-col>
                                <el-col :span="2" style="text-align: right">
                                    <!-- <el-button
                                        size="mini"
                                        type="primary"
                                        @click="queryList"
                                        >查询</el-button
                                    > -->
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="onRefresh()"
                                        >刷新</el-button
                                    >
                                </el-col>
                            </el-row>
                        </template>
                    </vxe-toolbar>

                    <vxe-table
                        ref="vxeTable"
                        border
                        resizable
                        show-overflow
                        :auto-resize="true"
                        :header-row-style="headerStyle"
                        :row-style="rowStyleFn"
                        align="center"
                        class="vxe-table-element"
                        height="600"
                        :data="records"
                    >
                        <vxe-table-column
                            type="checkbox"
                            width="60"
                            fixed="left"
                        />

                        <vxe-table-column type="seq" title="序号" width="120">
                            <template v-slot="{ rowIndex }">
                                {{ rowIndex + 1 }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column title="事故发生日期" width="120">
                            <template v-slot="{ row }">
                                {{ row.accidentTime }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column
                            title="事故发生地点"
                            show-header-overflow
                            show-overflow="title"
                            show-footer-overflow
                        >
                            <template v-slot="{ row }">
                                {{ row.accidentLocation }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column
                            title="事故性质"
                            show-header-overflow
                            show-overflow="title"
                            show-footer-overflow
                        >
                            <template v-slot="{ row }">
                                {{ row.accidentNature }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column
                            title="事故原因"
                            show-header-overflow
                            show-overflow="title"
                            show-footer-overflow
                        >
                            <template v-slot="{ row }">
                                {{ row.accidentAccident }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column title="所属车辆">
                            <template v-slot="{ row }">
                                {{ row.carNum }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column title="所属公司">
                            <template v-slot="{ row }">
                                {{ row.companyName }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column
                            title="车辆损坏情况"
                            show-header-overflow
                            show-overflow="title"
                            show-footer-overflow
                        >
                            <template v-slot="{ row }">
                                {{ row.damage }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column title="直接经济损失(元)">
                            <template v-slot="{ row }">
                                &yen; {{ row.directEconomicLoss }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column field="" title="操作" width="120">
                            <template v-slot="{ row }">
                                <el-dropdown size="small" type="primary">
                                    <el-button size="small" type="primary">
                                        操作<i
                                            class="el-icon-arrow-down el-icon--right"
                                        />
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item
                                            icon="el-icon-edit"
                                            @click.native="
                                                op.show = true;
                                                op.id = row.id;
                                                op.code = 'edit';
                                            "
                                            >修改
                                        </el-dropdown-item>
                                        <!-- <el-dropdown-item
                                            icon="el-icon-view"
                                            @click.native="
                                                op.show = true;
                                                op.id = row.id;
                                                op.code = 'look';
                                            "
                                            >详情
                                        </el-dropdown-item> -->
                                        <el-dropdown-item
                                            icon="el-icon-delete"
                                            @click.native="del(row.id)"
                                            >删除
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </vxe-table-column>
                    </vxe-table>

                    <div style="height: 20px"></div>
                    <el-pagination
                        background
                        :current-page.sync="ajaxParam.currentPage"
                        :page-sizes="[5, 10, 15, 20, 50, 100]"
                        :page-size.sync="ajaxParam.pageSize"
                        layout="total, ->, sizes, prev, pager, next, jumper"
                        :total="total"
                        @size-change="
                            (size) => {
                                ajaxParam.pageSize = size;
                                queryList();
                            }
                        "
                        @current-change="
                            (current) => {
                                ajaxParam.currentPage = current;
                                queryList();
                            }
                        "
                    />
                </el-row>
            </el-card>
        </el-row>

        <OperatData
            :op="op"
            :title="op.id == '0' ? '添加' : '修改'"
            :visible.sync="op.show"
            v-if="op.code == 'add' || op.code == 'edit'"
            @close="onClose"
            @query="queryList"
        ></OperatData>

        <LookData
            :op="op"
            title="查看"
            :visible.sync="op.show"
            v-if="op.code == 'look'"
            @close="onClose"
        ></LookData>
    </div>
</template>

<script>
import OperatData from "./components/OperatData.vue";
import LookData from "./components/LookData.vue";
export default {
    components: { LookData, OperatData },
    name: "knowledgeList",
    data() {
        return {
            daterange: [],
            ajaxParam: {
                currentPage: 1,
                pageSize: 20,
                companyId: this.$admin ? "" : this.$companyId,
                carId: "",
                startTime: "",
                endTime: "",
                // direction:"ASC" //排序方式，默认降序 DESC, ASC
                // property:""   // 排序字段，默认创建时间
            },

            records: [],
            total: 0,

            op: {
                code: "init", // "look" "edit" "add" 操作code
                show: false,
                id: "0", //id 为0则表示添加
                // row: null, //要操作的列表选中的一项  当前要操作的行
            },
        };
    },
    created() {
        this.queryList();
    },
    methods: {
        onRefresh() {
            // 刷新
            Object.keys((key) => {
                this.ajaxParam[key] = "";
            });
            this.ajaxParam.currentPage = 1;
            this.ajaxParam.pageSize = 20;
            this.queryList();
        },
        onClose() {
            this.op.id = "0";
            this.op.code = "init";
            this.op.show = false;
        },
        async queryList() {
            let { code, data } = await this.$api.get(
                "/car/server/escort/web/CarAccidentRecord",
                {
                    params: this.ajaxParam,
                }
            );
            if (code === 200) {
                this.records = data.content;
                this.total = ~~data.totalElements;
            }
        },

        del(val) {
            // 删除操作
            let ids = [];
            if (Array.isArray(val)) {
                //批量选择
                ids = this.$refs.vxeTable
                    .getCheckboxRecords()
                    .map((item) => item.id);
                if (ids.length === 0) {
                    this.$message({
                        message: "请选择要删除的数据",
                        type: "warning",
                        center: true,
                    });
                    return null;
                }
            }

            this.$confirm("确认删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    let { code } = await this.$api.delete(
                        `/car/server/escort/web/CarAccidentRecord/${
                            Array.isArray(val) ? ids + "" : val
                        }`
                    );
                    if (code === 200) {
                        this.$message({
                            message: "删除成功",
                            type: "success",
                            center: true,
                        });
                        this.queryList();
                    }
                })
                .catch(() => {});
        },
    },
};
</script>

<style scoped>
.dashboard-container {
    box-sizing: border-box;
    padding: 0 10px;
}
</style>
